<template>
  <div class="tariffs page">
    <div class="container">
      <div class="tariffs__title">Тарифные планы</div>
      <div class="tariffs__text">Выберите подходящий тариф для себя и используте приложение без ограничений</div>
      <div class="tariffs__cards">
        <div v-for="card in products" :key="card.id" class="tariffs__cards-item">
          <TariffCard :="card" @buyClick="buyProduct" />
        </div>
      </div>
      <div class="tariffs__info">
        <div class="tariffs__info-title">
          Для учебных заведений действуют ограничения*
        </div>
        <p>- оборудование (в совокупности не более 5 аппаратов и трубопроводов);</p>
        <p>- здания (не более 3);</p>
        <p>- работники (не более 5);</p>
        <p>- население - жилые, общественно-деловые зоны и зоны рекреационного назначения (не более 3).</p>
      </div>
    </div>
  </div>
</template>

<script>
import TariffCard from '../components/TariffCard'
import { ElMessageBox } from 'element-plus'

export default {
  name: 'Tariffs',
  components: {
    TariffCard,
  },
  data() {
    return {}
  },
  created() {
    if(this.$route.query.tarif) {
      this.buyProduct(this.$route.query.tarif)
    }
  },
  mounted(){
    this.getProducts()
  },
  computed:{
    products(){
      return this.$store.state.Products.products
    },
  },
  methods:{
    getProducts(){
      this.$store.dispatch('Products/getProducts')
        .then(resp => {
          console.log(resp.data.data) 
        })
        .catch(err => {
          console.log(err.response.data.errors)
        })
    },

    buyProduct(id){
      const data = {id} 
      this.$store.dispatch('Products/buyProduct', data)
        .then(resp => {
          ElMessageBox.alert(`Вы будете перенаправлены на страницу оплаты через 3 сек.`, {
            customClass: 'big-notification',
            showConfirmButton: false,
            showClose: false,
          })
          setTimeout(() => {
             document.location.href = resp.data.data.confirmation_url
             /* window.open(resp.data.data.confirmation_url) */ //В новой вкладке
          }, 3000);
        })
        .catch(err => {
          console.log(err.response.data.errors)
        })
    },
  },
}
</script>

<style scoped lang="scss">
@import '../assets/styles/helpers/variables';
@import '../assets/styles/helpers/mixin';

.tariffs {
  padding: 5rem 0;

  &__title {
    font-weight: 500;
    font-size: 2.6rem;
    line-height: 3.6rem;
    text-align: center;
    margin-bottom: 2.3rem;
  }

  &__text {
    font-size: 1.4rem;
    line-height: 1.9rem;
    margin-bottom: 6rem;
    text-align: center;
  }

  &__cards {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 4rem;

    &-item {
      width: 30%;
      margin-bottom: 6rem;
    }
  }

  &__info {
    font-size: 1.4rem;
    line-height: 1.9rem;

    &-title {
      font-weight: 600;
      margin-bottom: 1rem;
    }
  }
}
</style>
