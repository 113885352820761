<template>
  <div class="tariff-card">
    <div v-if="is_optimally" class="tariff-card__label">
      Оптимальный выбор
    </div>
    <div class="tariff-card__period">
      <span>{{ month }}</span> {{ getMonthText() }}
    </div>
    <div class="tariff-card__price">
      <div class="tariff-card__price-full">
        {{ parseInt(price).toFixed() }} ₽
      </div>
      <div v-if="old_price && old_price !== '0'" class="tariff-card__price-sale">
        {{ parseInt(old_price).toFixed() }} ₽
      </div>
    </div>
    <div class="tariff-card__additional">
      <div class="tariff-card__additional-title">
        {{role !== 'expert' ? 'Для учебных заведений*' : ''}}
      </div>
      <div class="tariff-card__additional-value">
        {{ parseInt(price).toFixed() }} ₽
      </div>
    </div>
    <ButtonElement v-if="user && user.role !== 'admin' " :text="`Купить на ${ month } ${ getMonthText() }`" @click="$emit('buyClick', id)"/>
    <ButtonElement v-else text="Редактировать тариф" @click="changeTariff(id, `${ month } ${ getMonthText() } ${role !== 'expert' ? 'для учебных заведений' : ''}`)"/>
  </div>
</template>

<script>
import ButtonElement from './elements/ButtonElement'

export default {
  name: 'TariffCard',
  components: {
    ButtonElement,
  },
  props: {
    id: {
      type: [String, Number],
      default: null,
    },
    month: {
      type: [String, Number],
      default: null,
    },
    price: {
      type: [String, Number],
      default: null,
    },
    old_price: {
      type: [String, Number],
      default: null,
    },
    currency: {
      type: [String, Number],
      default: null,
    },
    is_optimally: {
      type: [Boolean, Number],
      default: null,
    },
    role: {
      type: [String, Number],
      default: null,
    }
  },
  computed: {
    user(){
      return this.$store.state.Personal.user
    },
  },
  methods:{
    getMonthText() {
      if(this.month === 1) {
        return 'месяц'
      } else if(this.month === 3) {
        return 'месяца'
      } else {
        return 'месяцев'
      }
    },

    changeTariff(id, name) {
      this.$store.commit('Products/setData', { label: 'changinProductId', data: id })
      this.$store.commit('Products/setData', { label: 'changinProduct', data: {price: this.price, old_price: this.old_price, is_optimally: this.is_optimally } })
      this.$store.commit('Dialog/setData', { label: 'activeDialog', data: 'ChangeTariff' })
      this.$store.commit('Dialog/setData', { label: 'titleDialog', data: `Изменить тариф ${name}` })
      this.$store.commit('Dialog/setData', { label: 'dialogVisible', data: true })
    }
  },

}
</script>

<style scoped lang="scss">
@import '../assets/styles/helpers/variables';
@import '../assets/styles/helpers/mixin';

.tariff-card {
  background: $basicWhite;
  box-shadow: 0 6px 64px rgba(0, 0, 0, 0.15);
  border-radius: 1.2rem;
  position: relative;
  padding-bottom: 2rem;

  &__label {
    position: absolute;
    left: 4rem;
    background: $brightOrange;
    border-radius: 3rem;
    font-weight: 700;
    font-size: 1.2rem;
    text-transform: uppercase;
    line-height: 1.6rem;
    color: $basicWhite;
    padding: 0.6rem 1rem;
    top: -1.4rem;
  }

  &__period {
    height: 12.8rem;
    padding: 0 4rem;
    background: url("../assets/images/tarrif-one.png") no-repeat;
    background-size: cover;
    color: $basicWhite;
    font-weight: 700;
    font-size: 1.8rem;
    line-height: 12.8rem;
    border-radius: 1.2rem 1.2rem 0 0;
    overflow: hidden;

    span {
      font-weight: 800;
      font-size: 6.4rem;
      margin-right: 1rem;
    }
  }

  &__price {
    border-bottom: 1px solid $lightGrey;
    padding: 2rem 4rem;
    display: flex;
    align-items: flex-start;

    &-full {
      font-size: 4.2rem;
      line-height: 5.7rem;
      font-weight: 700;
    }

    &-sale {
      font-weight: 700;
      font-size: 1.8rem;
      line-height: 2.5rem;
      color: $darkGrey;
      text-decoration: line-through;
      margin-left: 2rem;
      margin-top: 0.8rem;
    }
  }

  &__additional {
    padding: 2rem 4rem 5rem;

    &-title {
      font-size: 1.4rem;
      line-height: 1.9rem;
      margin-bottom: 1rem;
    }

    &-value {
      font-weight: 700;
      font-size: 2.4rem;
      line-height: 3.3rem;
    }
  }

  .button {
    width: calc(100% - 4rem);
    margin: 0 auto;
  }
}
</style>
